import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/signup.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import customerCover from '../images/customer-cover.svg'
import operationsCover from '../images/operations-cover.svg'
import employeeCover from '../images/employee-cover.svg'
import actionableCover from '../images/actionable-cover.svg'
import servicesCover from '../images/services-cover.svg'
import customer from '../images/customer.svg'
import operations from '../images/operations.svg'
import employee from '../images/employee.svg'
import actionable from '../images/actionable.svg'
import services from '../images/services.svg'

const Signup = ({ data, location }) => {
	const {
		state = {
			productName: 'Customer Engagement',
			planTitle: 'Starter Plan',
			planPrice: '1 $',
		},
	} = location

	const signupState =
		state && state.productName
			? state
			: JSON.parse(localStorage.getItem('state')) || {
					productName: 'Customer Engagement',
					planTitle: 'Starter Plan',
					planPrice: '1 $',
			  }
	const themeCase = [
		{ productName: 'Customer Engagement', background: customerCover, image: customer },
		{ productName: 'Operations Excellence', background: operationsCover, image: operations },
		{ productName: 'Employees Productivity', background: employeeCover, image: employee },
		{ productName: 'Actionable Insights', background: actionableCover, image: actionable },
		{ productName: 'Services', background: servicesCover, image: services },
		{ productName: 'Market Place', background: servicesCover, image: services },
	]
	const handleBackground = () => {
		if (signupState)
			return (
				'url(' +
				themeCase.find(item => item.productName === signupState.productName).background +
				') center/cover no-repeat'
			)
		else return 'url(' + servicesCover + ') center/cover no-repeat'
	}
	const handleLayer = () => {
		if (signupState)
			return (
				'url(' +
				themeCase.find(item => item.productName === signupState.productName).image +
				') center/cover no-repeat'
			)
		else return 'url(' + services + ') center/cover no-repeat'
	}
	return (
		<Fragment>
			<Layout>
				<Helmet>
					<meta name="robots" content="noindex" />
				</Helmet>
				{/* <SEO title="Signup" /> */}
				<div className="signup-page" style={{ background: handleLayer() }}>
					<div className="Header-parent">
						<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
					</div>
					<div className="page-content" style={{ background: handleBackground() }}>
						<div className="steps-parent">
							<MDXRenderer>{data.Steps.nodes[0].body}</MDXRenderer>
						</div>
						<div className="step-content signup-step" id="step2">
							<div className="step-parent">
								<MDXRenderer state={signupState}>
									{data.Signup.nodes[0].body}
								</MDXRenderer>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</Fragment>
	)
}
export const pageQuery = graphql`
	query SignupQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Steps: allMdx(filter: { frontmatter: { title: { eq: "Steps" } } }) {
			nodes {
				body
			}
		}
		Signup: allMdx(filter: { frontmatter: { title: { eq: "Signup" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Signup
